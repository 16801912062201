<template>
    <b-table :items="data.rows" :fields="fields" striped hover responsive class="all-items">
        <template v-slot:cell(AttemptCounter)="data">
            <div class="text-center">
                {{ data.value }}
            </div>
        </template>
    </b-table>
</template>
<script>
export default {
    data() {
        return {
            fields: [
                    { key: 'AttemptCount', label: 'Attempt Count' },
                    { key: 'MissingVendorDocs', label: 'Pending Verification Documents' },
                    { key: 'DateOfVendorContact', label: 'Date Of Vendor Contact' },
                    { key: 'MethodforReachingOut', label: 'Method for Reaching Out' },
                    { key: 'Comments', label: 'Comments' },
                    { key: 'ConfirmPaymentInfo', label: 'Did the vendor confirm the payment information?' },
                    { key: 'VendorACHInfo', label: 'Did the vendor provide an ACH/Wire info sheet?' },
                    { key: 'VendorValidatorEmailId', label: 'Validator Email Id' },
                ],
            data: {
                rows: [],
            },
        }
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
    },
    mounted() {
        this.loadAttemptHistory()
    },
methods: {
    loadAttemptHistory() {
            this.data.rows = [];
                this.$store
                    .dispatch("vendorValidation/GetAttemptHistory", this.id)
                    .then((res) => {
                        if(res.data.find(el => el.AttemptCount > 1)) {
                            this.ShowHistoryIcon = true;
                        }
                        this.data.rows = res.data.map(el => ({
                            AttemptCount: el.AttemptCount,
                            MissingVendorDocs: el.MissingVendorDocs,
                            DateOfVendorContact: new Date(el.DateOfVendorContact).toLocaleDateString(),
                            MethodforReachingOut: el.MethodforReachingOut,
                            Comments: el.Comments,
                            ConfirmPaymentInfo: el.ConfirmPaymentInfo == true ? "Yes" : el.ConfirmPaymentInfo == false ? "No" : "",
                            VendorACHInfo: el.VendorACHInfo == true ? "Yes" : el.VendorACHInfo == false ? "No" : "",
                            VendorValidatorEmailId: el.VendorValidatorEmailId,
                        }))
                    })
                    .catch((err) => {
                        this.Error = true;
                        alert(err);
                    });
        },
}
}
</script>

<style>
.modal.show .modal-dialog {
    min-width: 80% !important;
}
</style>